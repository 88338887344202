
import { MouseEventHandler} from 'react'
import tw, { classnames, TArg, position, inset, zIndex, translate, cursor } from "tailwindcss-classnames";
import {NextIcon, PreviousIcon} from '../Icons'

type ArrowProps = {
    onClick?: MouseEventHandler<HTMLDivElement>
    twClassName?: TArg | string
}

const arrowStyle = `scale-75 "sm:scale-100" ${classnames(position("absolute"), inset("bottom-0", "xl:top-1/2", "xl:bottom-auto"), zIndex("z-10"), translate("translate-y-full"), cursor("cursor-pointer"))}`

export const CarouselNext = ({onClick, twClassName}: ArrowProps) => {
    return (
        <div className={`${arrowStyle} "xl:-left-8" ${twClassName} ${classnames(inset("left-5", "sm:left-6", "md:left-0", "right-auto"))}`} onClick={onClick}>
            <PreviousIcon />
        </div>
    )
} 

export const CarouselPrev = ({
    onClick, 
    twClassName
}: ArrowProps) => {
    return (
        <div className={`${arrowStyle} xl:-right-8 ${twClassName} ${classnames(inset("right-5", "sm:right-6", "md:right-0", "left-auto"))}`} onClick={onClick}>
            <NextIcon  />
        </div>
    )
}
