import Image from 'next/image'

import { Carousel, CarouselNext, CarouselPrev } from "..";
import { Image as ImageInf } from 'src/packages/domain';


type Props = {
    gallery: ImageInf[]
}

const sliderSettings = {
    lazyLoad: "progressive",
    centerMode: true,
    centerPadding: "0",
    dots: false,
    easing: "fade-in-out",
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarouselNext />,
    prevArrow: <CarouselPrev />
}

const Gallery = ({gallery}: Props) => {
    return (
        <>
            {gallery && gallery
                .slice(0,3)
                .map((image,i) => (
                    <div className="relative flex h-1/2vh md:h-3/4vh xl:h-600 w-full" key={i} >
                        <Image 
                            src={image.src}
                            alt={image.alt || "art"}
                            layout="fill"
                            objectFit="contain"
                        />
                    </div>
                ))
            }
        </>
    )
}

export default Gallery